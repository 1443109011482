<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />

    <CRow>
      <CCol col="md-6">
        <BlockInfo
          :title="$t('last_app_version') + ':'"
          v-model="_mobileSettings.lastAppVersion"
        />
      </CCol>
     <!-- <CCol col="md-6">
        <BlockInfo
          title="Last Version Path:"
          v-model="_mobileSettings.lastVersionPath"
          :disabled="true"
        />
      </CCol>-->
      <CCol col="md-6">
        <BlockInfo
          :title="$t('minimum_app_version') + ':'"
          v-model="_mobileSettings.minimumAppVersion"
        />
      </CCol>
    </CRow>

    <div class="versions-sectionButtons">
      <CButton
        color="success"
        size="lg"
        class="versions-button"
        @click="updateMobileSettings()"
        >{{ $t('save') }}</CButton
      >
    </div>

    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import BlockInfo from "../components/BlockInfo";

export default {
  name: "Versions",
  components: {
    BlockInfo,
  },
  data() {
    return {
      loading: false,

      // props toast
      toastShow: false,
      toastColor: "",
      toastMessage: "",

      mobileSettings: {
        lastAppVersion: "",
        minimumAppVersion: "",
        lastVersionPath: "",
      },
    };
  },
  computed: {
    _mobileSettings() {
      return this.mobileSettings;
    },
  },
  async created() {
    await this.getMobileSettings();
  },
  methods: {
    async getMobileSettings() {
      const self = this;

      try {
        const res = await self.$http.get("apiConsole/console/getMobileConfig");
        self.mobileSettings = res.data.mobileSettings;
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
    },
    async updateMobileSettings() {
      const self = this;
      try {
        const res = await self.$http.put(
          "apiConsole/console/updateMobileConfig",
          {
            lastAppVersion: self._mobileSettings.lastAppVersion,
            minimumAppVersion: self._mobileSettings.minimumAppVersion,
          }
        );
        self.showToastInfo("Success updating version");
        console.log(res.data);

        await self.getMobileSettings();
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },

    showToastError(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "danger";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
    showToastInfo(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "success";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
  },
};
</script>

<style scoped>
.versions-sectionButtons {
  padding: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.versions-button {
  margin-left: 1em;
  font-size: 14px;
}
.main-toast {
  z-index: 11000;
}
</style>